<template>
  <section id="header" style="background-color: black">
    <nav class="navbar navbar-expand-md navbar-light" id="navbar_sticky">
      <div class="container-xl">
        <a class="navbar-brand fs-3 p-0 fw-bold text-white" href="/"
          ><img src="1.png" alt="Kigali Rwanda Car Hire" title="Kigali Rwanda Car Hire" class="rounded-circle" height="100" /><i
            class=""
          ></i>
          Kigali Rwanda Car Hire
          <!-- <br /> -->
          <span class="fs-5 span_1"></span
        ></a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mb-0">
  <li class="nav-item">
    <router-link
      to="/"
      class="nav-link"
      :class="{ active: $route.path === '/' }"
    >
      Home
    </router-link>
  </li>
  <li class="nav-item">
    <router-link
      to="/about"
      class="nav-link"
      :class="{ active: $route.path === '/about' }"
    >
      About
    </router-link>
  </li>
  <li class="nav-item">
    <router-link
      to="/car-listing"
      class="nav-link"
      :class="{ active: $route.path === '/car-listing' }"
    >
      Car Models
    </router-link>
  </li>
  <li class="nav-item">
    <router-link
      to="/clients-review"
      class="nav-link"
      :class="{ active: $route.path === '/clients-review' }"
    >
      Clients Review
    </router-link>
  </li>
  <li class="nav-item">
    <router-link
      to="/contact"
      class="nav-link"
      :class="{ active: $route.path === '/contact' }"
    >
      Contact
    </router-link>
  </li>
</ul>
          <ul class="navbar-nav mb-0 ms-auto">
            <!-- <li class="nav-item">
              <a class="nav-link" href="#">Sign In</a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link button_2 ms-2 me-2" href="/car-listing"
                >Book a Car <i class="fa fa-check-circle ms-1"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </section>
</template>
<style scoped>
#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensures the header stays on top */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add a shadow for better visibility */
}

/* Add padding to the body or main content to prevent overlapping */
body {
  padding-top: 80px; /* Adjust based on the header height */
}

</style>
